.App {
    text-align: center;
    background-color: rgb(16, 11, 87);
    min-height: 100vh;
}

.app-logo {
    height: 40px;
    width: 192px;
}

.passwordreset {
    width: 55px;
    height: 89px;
}

.app-header {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 7%;
    margin-bottom: 3%;
}

.app-footer {
    font-size: 21px;
    line-height: 32px;
    color: rgb(255, 250, 238);
    opacity: 0.6;
    margin-bottom: 29%;
}

.contact-support {
    font-family: 'Averta-Bold', Helvetica, Verdana, sans-serif;
}

.having-troube {
    font-family: 'Averta', Helvetica, Verdana, sans-serif;
}

.title {
    font-size: 56px;
    font-family: 'Averta-ExtraBold', Helvetica, Verdana, sans-serif;
    color: rgb(255, 250, 238);
}
.feedback {
    height: 52pt;
}
.sub-title {
    font-size: 21px;
    line-height: 32px;
    font-family: 'Averta', Helvetica, Verdana, sans-serif;
    color: rgb(255, 250, 238);
}
.code-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 35%;
    margin-right: 35%;
    margin-bottom: 6%;
}
.code-input {
    height: 60px;
    width: 59px;
    border-radius: 15px;
    border: 0;
    display: block;
    font-family: 'Averta-ExtraBold', Helvetica, Verdana, sans-serif;
    font-size: 28pt;
    text-align: center;
}
.button {
    border-radius: 25pt;
    width: 147pt;
    height: 42pt;
    margin-bottom: 6%;
    font-family: 'Averta-Semibold', Helvetica, Verdana, sans-serif;
    font-size: 14pt;
}

main {
    display: block;
}
